import React, {useState} from 'react'
import { Navigate } from "react-router-dom";
import Select from 'react-select'

 const Content = (usuario) => {
  const [iframe, setIframe] = useState('');
  const statuto = localStorage.status;
let defaultValue = 'Seleccione';
  const showSelect =()=>{
    var select = document.getElementById('selectIframe').value;
setIframe(select);
    
  }
  
 return (<>



      

<div className="content-wrapper content-align-center">   
 <div className="container">

      <select id='selectIframe' className='form-control' defaultValue={defaultValue} onChange={showSelect}>
      <option selected>Seleccione Reporte</option>
      {usuario.usuario.Iframes?.map(i => {
            return  (<><option value={i.Iframe}>{i.NameReport}</option></>)
      })}
      </select>

</div> 


 <div style={{marginTop:'2rem'}} dangerouslySetInnerHTML={{__html:iframe}}></div>
</div> 
    
</>)
  
  
}

export default Content;