import React, {useState} from 'react'
import {redirect, useNavigate} from "react-router-dom";
import { URL_API_UPCODE } from '../Utilities/constantes';

const Login = () => {
const navigate = useNavigate();
  const[formulario, setFormulario] = useState({
    Username:"",
    Password:""
  });

  const [token, setToken] = useState();
  const [error, setError] = useState("");


  const enviarlogin = async (formulario) => {
    console.log(formulario);
    console.log("entramos al enviarlogin");
    try {
      const result = await fetch(URL_API_UPCODE+"/api/Auth/Login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Username: formulario.Username,
          Password: formulario.Password,
        }),
      });
     
      console.log("resultado_:");
      console.log(result);
      
     
      if (result.ok){
       const respuestaJson = await result.json();
       console.log("respuesta json:");
       console.log(respuestaJson);
       const token2 = respuestaJson.value; 
        
       
      
     
      
        setToken(token2);
        localStorage.setItem("token",token2);
       
        if(respuestaJson.statusCode === 404 ){
          setError(respuestaJson.value);
            // navigate("/");
            redirect("/")
          
        }
        else{
          localStorage.setItem("Usuario",formulario)
          localStorage.setItem("status",true);
          navigate("/Home",{
            state:{
              logged:true,
              nombre:formulario.Username,
              token:token2,
              status:respuestaJson.statusCode
            }
          });        
        }
          } else {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };

  function handleForm(e){
    
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value
    })
    
   
  }

  function handleEnviar(e){
    e.preventDefault();   
    enviarlogin(formulario);
}


  return (
   <div className='row justify-content-center' style={{marginTop:'10rem'}}>
    <div className="login-box">
  {/* /.login-logo */}
  <div className="card card-outline card-primary">
    <div className="card-header text-center">
      <a href="/" className="h1"><b>Analytics</b> PW-BI</a>
    </div>
    <div className="card-body">
      <p className="login-box-msg">Ingrese sus credenciales</p>
    

    {/*Formulario */}
      <form onSubmit={(e)=>handleEnviar(e)}>
        <div className="input-group mb-3">
          <input value={formulario.Username} type="text" name='Username' className="form-control" placeholder="Usuario" onChange={(e)=>handleForm(e)} />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user" />
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input value={formulario.Password} type="password" name='Password' className="form-control" placeholder="Password" onChange={(e)=>handleForm(e)}/>
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock" />
            </div>
          </div>
        </div>
        <div style={{textAlign:"center", marginBottom:10, color:"red"}}>
          {error ? error:"-"}
        </div>
        <div className="row">         
          {/* /.col */}
          <div className="col-md-6">
            <button type="submit" className="btn btn-primary btn-block">Iniciar Sesión</button>
            
          </div>
          {/* /.col */}
        </div>
      </form>       




    </div>
    {/* /.card-body */}
  </div>
  {/* /.card */}
</div>
   </div>

  )
}

export default Login;
