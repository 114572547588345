import React, {useState, useEffect}  from 'react'


const TipoCambio = () => {
    const [change, setChange] = useState({});

    const getTipoCambio = async () => {
      const response = await fetch("https://api.cambio.today/v1/full/USD/json?key=39758|9ueB47QMjFWKC6BGdx*cmaub7yr~o8C5", {
        method: "GET",
        headers:{"Content-Type": "application/json"}
      });
      const result = await response.json();
      const cahanges = await result.Entities;
      if(cahanges.status =='OK'){
        setChange(cahanges);
        console.log('Test', change.result)
      }else{
        console.log('Error: Falló la consulta');
      }
      
    }
    
    useEffect(() => {
     if(getTipoCambio() == true){
        console.log('fue exitoso!')
     }else{console.log('Error en el fetch')}
    }, []);

  return (<>
     <div>TipoCambio USD</div>

<table>
    <thead>
        <tr>
            <th>Fecha</th>
            <th>País</th>
            <th>Cambio</th>
        </tr>
    </thead>
    <tbody>
        
            {/* {change.result.conversion?.map((x, i)=>{
                return <tr> 
                    <td key={i}>{x.date}</td>
                    <td key={i}>{x.to}</td>
                    <td key={i}>{x.rate}</td>
                    </tr>
            })} */}
            
        
    </tbody>
</table> 
    </>)
}

export default TipoCambio;
