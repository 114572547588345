import { createBrowserRouter } from "react-router-dom";
import TipoCambio from "../Components/TipoCambio";
import Login from "../Components/Login";
import Home from "../Components/Home";


export const Router = createBrowserRouter([
    {
        path: "/",
        element: <Login/>,
    },
    {
        path: "/Home",
        element:<Home/>,
    },
    {
       path:'/TipoCambio',
       element:<TipoCambio/>,
    }

   
]);