import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import Aside from '../Components/Aside'
import Content from '../Components/Content'
import Footer from '../Components/Footer'  
import{useLocation,useNavigate} from 'react-router-dom' 
import { URL_API_UPCODE } from '../Utilities/constantes'                                       

  const Home = () => {
    const [nuevo, setDatos] = useState([]);
    const navigate = useNavigate();
  const estado = useLocation().state;
  const tokesito = useLocation().state;

  const { state } = useLocation();
  const statuto = localStorage.status;
  const t = localStorage.getItem("token");
  const user = localStorage.getItem("Usuario");

 

  const getServices = async () => {
    
    const respuesta = await fetch(URL_API_UPCODE + "/api/Services/AnalyticsPowerBi", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + t,
        "Content-Type": "application/json",
      }
      
    });
    

    console.log(respuesta);

    if(respuesta.ok){
    const result = await respuesta.json();        
    const data = await result.Entity;
    
    console.log(data);
    console.log(data.IsActive);
    if(!data.IsActive){
       navigate("/");
       
    }
    else{
      console.log("El usuario esta activo");
    }
    setDatos(data);
    }
   
   
  }

  useEffect(() => {
    // if (statuto) {
    //   setAuth(true);
    // }

    getServices();
    
  }, []);

 return (
    <div>
      
      {t==null && user==null?window.location="/":<div><Header />
      <Aside usuario={nuevo}/>
      <Content usuario={nuevo}/>
      <Footer/></div> }
      
    </div>
  )
}

export default Home;
