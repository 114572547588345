import React from 'react'

export default function Footer() {
  return (
    <footer className="main-footer">
  <strong>Copyright © 2017 - {new Date().getFullYear()} <a href="https://upcode.cl">UP Code E.I.R.L.</a>.</strong>
  All rights reserved.
  <div className="float-right d-none d-sm-inline-block">
    <b>Version</b> 3.2.0
  </div>
</footer>

  )
}
