import React from 'react'
import { useNavigate } from "react-router-dom";
const Aside = (usuario) => {

  const navigator = useNavigate();

  function cerrar(){
    localStorage.setItem("status","");
    localStorage.setItem("cliente","");
    localStorage.removeItem("token");
  navigator("/");
    
  }
  return (
   <aside className="main-sidebar sidebar-dark-primary elevation-4">
  {/* Brand Logo */}
  
  {/* Sidebar */}
  <div className="sidebar">
    {/* Sidebar user panel (optional) */}
    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
      <div className="image">
        <img src="dist/img/user2-160x160.png" className="img-circle elevation-2" alt="User Image" />
      </div>
      <div className="info">
        <a href="#" className="d-block">{usuario.usuario.RazonSocial}</a>
      </div>
      
    </div>
    <div className="container">
      <button className="btn btn-warning btn-block" onClick={cerrar} style={{color:'blue'}}> Cerrar Sesión</button>
      </div>
    {/* SidebarSearch Form */}
   
    {/* Sidebar Menu */}
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
       
        
      </ul>
    </nav>
    {/* /.sidebar-menu */}
  </div>
  {/* /.sidebar */}
</aside>
  )
}

export default Aside;
